import EventBus from '@/util/EventBus'
import AccountService from '@/services/account.service.js'
import ProfileMenu from '../Common/ProfileMenu/ProfileMenu.vue'
import SupportCard from '../Common/SupportCard/SupportCard.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'ChangePassword',
  components: {
    HeaderTopDashboard,
    ProfileMenu,
    SupportCard,
  },
  data: function () {
    return {
      email: '',
      username: '',
      emails: [],
      loading: false,
    }
  },
  mounted () {
    this.getEmails()
    this.setUsername()
  },
  methods: {
    sendLink () {
      this.loading = true
      AccountService.sendLink({ email: this.email, username: this.username }).then(
        () => {
          EventBus.$emit('showAlert', 'success', this.$t('Se ha enviado un link al correo electrónico para cambiar la contraseña.'))
        },
        (error) => {
          AccountService.errorResponse(
            error.response.status,
            this.$t('Error al intentar cambiar la contraseña'),
            this.$t(error.response.data.message),
          )
        },
      )
      .finally(() => {
        this.loading = false
      })
    },
    getEmails () {
      AccountService.getEmails().then((data) => {
        this.emails = data.emails
        this.email = data.emails[0]
      })
    },
    setUsername () {
      AccountService.getProfile().then((profileData) => {
        this.username = profileData.user.username
      })
    },
  },
}
